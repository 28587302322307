import request from '@/utils/request';

// 礼品 列表
export const giftList = params => request.post(`/study/gift/list`, params)

// 礼品 详情
export const giftDetail = id => request.post(`/study/gift/${id}/detail`)

// 礼品 兑换
export const giftExchange = params => request.post(`/study/gift/exchange`, params)

// 礼品 兑换列表
export const giftExchangeList = params => request.post(`/study/gift/exchange/list`, params)

// 礼品 兑换规则
export const giftRule = params => request.get(`/study/shop/rule`, params)