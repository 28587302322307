<template>
  <OtherBanner :type="45" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <div class="integral">
        <div class="left">
          <div class="gold">
            <img src="../../assets/image/gold.png" alt="gold" />
          </div>
          <span class="label">{{ $t("integral.available_integral") }}：</span>
          <!-- 可用积分： -->
          <span class="value">{{ myIntegral }}</span>
        </div>
        <div class="right">
          <router-link to="/shop/order">{{
            $t("shop.my_exchange_record")
          }}</router-link>
          <!-- 我的兑换记录 -->
          <span style="color: #ccc; font-size: 14px; padding: 0 5px">｜</span>
          <router-link to="/shop/rule">{{
            $t("shop.integral_rule")
          }}</router-link>
          <!-- 积分兑换规则 -->
        </div>
      </div>
      <!-- 兑换状态 -->
      <ListFilter
        only
        :name="$t('shop.exchange_status')"
        :dataSource="typeStatus"
        @onChange="typeChange"
        class="filter-lang"
      >
        <template v-slot:right>
          <div class="order-box">
            <a
              href="javascript:;"
              :class="{ active: orderActive === 1 }"
              @click="orderChange(1)"
            >
              {{ $t("shop.points_required") }}
              <!-- 所需积分 -->
              <span class="sort">
                <CaretUpOutlined
                  class="icon"
                  :class="{ on: orderActive === 1 && sortActive === 1 }"
                />
                <CaretDownOutlined
                  class="icon"
                  :class="{ on: orderActive === 1 && sortActive === 2 }"
                />
              </span>
            </a>
            <a
              href="javascript:;"
              :class="{ active: orderActive === 2 }"
              @click="orderChange(2)"
            >
              {{ $t("shop.added_time") }}
              <!-- 上架时间 -->
              <span class="sort">
                <CaretUpOutlined
                  class="icon"
                  :class="{ on: orderActive === 2 && sortActive === 1 }"
                />
                <CaretDownOutlined
                  class="icon"
                  :class="{ on: orderActive === 2 && sortActive === 2 }"
                />
              </span>
            </a>
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div class="crumbs"></div>
      <div class="search">
        <div class="search-icon" @click="search">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="search"
        />
        <!-- 请输入关键字 -->
      </div>
    </div>
  </section>

  <section class="gift">
    <div class="content">
      <a-spin :spinning="giftLoading">
        <template v-if="giftData.length">
          <div class="cards clearfix">
            <div class="item fl" v-for="item in giftData" :key="item.giftId">
              <div class="cover">
                <img :src="item.coverImg" alt="cover" />
                <p v-if="item.price">
                  {{ $t("cm_price") }}： {{ $t("currency_symbol") }}
                  {{ item.price }}
                </p>
                <!-- 价格 -->
              </div>
              <div class="info">
                <h5>{{ item.giftName }}</h5>
                <div class="bottom">
                  <span>
                    <strong
                      >{{ item.integral }} {{ $t("LB_Pro_Integral") }}</strong
                    >
                    <!-- 积分 -->
                    <i>{{ $t("shop.stock") }}：{{ item.stocks }}</i>
                    <!-- 库存 -->
                  </span>
                  <a
                    :href="`/shop/detail?giftId=${item.giftId}${
                      newWindow == 1 ? '&ddtab=true' : ''
                    }`"
                    :target="newWindow == 1 ? '_blank' : '_self'"
                    >{{ $t("Lab_Mall_Conversion") }}</a
                  >
                  <!-- 立即兑换 -->
                </div>
              </div>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="20"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t("cm_pre") }}</a-button>
                <a-button v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useStore } from "vuex";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import { giftList, giftRule } from "@/api/shop";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
    OtherBanner,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const myIntegral = ref(0);
    giftRule().then((res) => {
      myIntegral.value = res.data.integral || 0;
    });

    // 来源
    const typeStatus = [
      {
        name: $t("LB_Mall_ICanExchange"),
        // 我可兑换的
        id: 1,
      },
    ];
    let giftType = 0;
    const typeChange = (arr) => {
      giftType = arr[0];
      getGiftList();
    };

    // 排序
    const orderActive = ref(0),
      sortActive = ref(0),
      orderChange = (type) => {
        if (orderActive.value == type) {
          switch (sortActive.value) {
            case 1:
              sortActive.value = 2;
              orderActive.value = type;
              break;
            case 2:
              orderActive.value = 0;
              sortActive.value = 0;
              break;
          }
        } else {
          sortActive.value = 1;
          orderActive.value = type;
        }
        getGiftList();
      };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getGiftList();
      };

    // 课程
    const giftData = ref([]),
      giftLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getGiftList = (scroll = false) => {
        giftLoading.value = true;
        giftList({
          page: currentPage.value,
          pageSize: 20,
          keyword: searchVal.value,
          order: orderActive.value,
          sort: sortActive.value,
          giftType: giftType,
        }).then((res) => {
          giftLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            giftData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getGiftList(true);
      };
    getGiftList();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      myIntegral,
      typeStatus,
      typeChange,
      orderActive,
      sortActive,
      orderChange,
      searchVal,
      search,
      getGiftList,
      giftData,
      giftLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    // ::v-deep(.filter-lang) {
    //   .label {
    //     width: 78px !important;
    //   }
    //   .values {
    //     width: calc(100% - 88px) !important;
    //   }
    // }
    .integral {
      padding: 0 10px;
      height: 62px;
      border-bottom: 1px solid #eff1f4;
      .mixinFlex(space-between; center);
      .left {
        .mixinFlex(flex-start; center);
        .gold {
          width: 23px;
          height: 23px;
          border-radius: 50%;
          background-color: #999999;
          .mixinFlex(center; center);
          margin-right: 10px;
        }
        .label {
          font-size: 18px;
          line-height: 30px;
          color: #999999;
        }
        .value {
          font-family: "Arial";
          font-size: 22px;
          line-height: 30px;
          color: #dd0c16;
        }
      }
      .right {
        color: #999999;
        user-select: none;
        a {
          font-size: 14px;
          line-height: 21px;
          color: #999999;
          &:hover {
            color: @color-theme;
          }
        }
      }
    }
    .order-box {
      height: 34px;
      .mixinFlex(flex-end; center);
      a {
        font-size: 14px;
        color: #666;
        margin-left: 26px;
        .mixinFlex();
        .sort {
          .mixinFlex(center; center; column);
          margin: -2px 0 0 3px;
          .icon {
            font-size: 10px;
            height: 8px;
            color: #999;
          }
        }
        &:hover,
        &.active {
          color: @color-theme;
          .on {
            color: @color-theme;
          }
        }
      }
    }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 34px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.gift {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        width: 282px;
        height: 300px;
        background: #ffffff;
        border-radius: 8px;
        margin-right: 24px;
        margin-bottom: 24px;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        .cover {
          .mixinImgWrap(282px; 214px);
          img {
            border-radius: 4px 4px 0 0;
          }
          p {
            margin: 0;
            padding: 0 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 24px;
            line-height: 24px;
            color: #fff;
            font-size: 12px;
            background: rgba(149, 109, 84, 0.2);
          }
        }
        .info {
          padding: 12px 16px 18px;
          h5 {
            .mixinEllipsis(24px);
            font-size: 16px;
          }
          .bottom {
            margin-top: 10px;
            font-size: 14px;
            .mixinFlex(space-between; center);
            span {
              line-height: 14px;
              strong {
                color: #f5961b;
                font-weight: 700;
              }
              i {
                color: #999;
                font-style: normal;
                padding: 0 12px;
                margin-left: 12px;
                border-left: 1px solid #ddd;
              }
            }
            a {
              background-color: @color-theme;
              color: #fff;
              width: 77px;
              height: 26px;
              text-align: center;
              line-height: 26px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
