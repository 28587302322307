<template>
  <section v-if="bannerData && bannerData.length" class="benner">
    <div class="img">
      <img :src="bannerData[0].coverPath" alt="" />
    </div>
  </section>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { getBanner } from "@/api/other";
import ls from "@/utils/local-storage";
export default {
  name: "OtherBanner",
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      bannerData: [],
    });

    const getInfo = () => {
      let s = ls.get("otherBanner");
      if (s) {
        state.bannerData = s.filter((item) => {
          return item.type == props.type;
        });
      }
      request();
    };
    const request = () => {
      getBanner().then(async (res) => {
        let otherBanner = [];
        res.data = res.data.filter((item) => {
          if (item.bannerType != 1) {
            otherBanner.push({
              type: item.bannerType,
              coverPath: item.coverPath,
            });
          }
          return item.bannerType == 1;
        });
        store.dispatch("setOtherBanner", otherBanner);
        state.bannerData = otherBanner.filter((item) => {
          return item.type == props.type;
        });
      });
    };
    getInfo();
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  background-color: @color-page-gray;
  height: 200px;
  .img {
    .mixinFlex(center; center; column);
    width: 1200px;
    height: 200px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 8px 8px 0 0;
  }
}
</style>
